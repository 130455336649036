import Vue from 'vue'
import App from './App.vue'
import qs from 'qs'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import './assets/gloable.css';
import request from "@/utils/request";
import Cookies from 'js-cookie'

Vue.config.productionTip = false

Vue.use(ElementUI,{size: "mini"});
// Vue.use(Element, { size: 'small', zIndex: 3000 });

Vue.prototype.$cookie = Cookies
Vue.prototype.request = request
Vue.prototype.$qs = qs


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
