import axios from 'axios'
import ElementUI from "element-ui";
import Cookies from 'js-cookie'


const request = axios.create({
    // baseURL: 'http://121.40.155.100:9090',
    // baseURL: 'http://localhost:9090',
    baseURL: 'http://api.m.zhiwenjiaoyu.cn/',
    timeout: 5000
})

// request 拦截器
// 可以自请求发送前对请求做一些处理

// 比如统一加token，对请求参数统一加密
request.interceptors.request.use(config => {
    config.headers['Content-Type'] = 'application/json;charset=utf-8';
    let admin = Cookies.get("admin") ? JSON.parse(Cookies.get("admin")) : null;
    if(admin){
        config.headers['token'] = admin.token;  // 设置请求头
        config.headers['user'] = "admin"
    }
    let salesman = Cookies.get("salesman") ? JSON.parse(Cookies.get("salesman")) : null;
    if(salesman){
        config.headers['token'] = salesman.token;  // 设置请求头
        config.headers['user'] = "salesman"
    }
    let writer = Cookies.get("writer") ? JSON.parse(Cookies.get("writer")) : null;
    if(writer){
        config.headers['token'] = writer.token;  // 设置请求头
        config.headers['user'] = "writer"
    }
    return config
}, error => {
    return Promise.reject(error)
});

// response 拦截器
// 可以在接口响应后统一处理结果
request.interceptors.response.use(
    response => {
        let res = response.data;
        // 如果是返回的文件
        if (response.config.responseType === 'blob') {
            return res
        }
        // 兼容服务端返回的字符串数据
        if (typeof res === 'string') {
            res = res ? JSON.parse(res) : res
        }
        if(res.code === '401'){
            ElementUI.Message({
               message: res.msg,
               type: 'error',
            });
        }
        return res;
    },
    error => {
        //console.log('err' + error) // for debug
        return Promise.reject(error)
    }
)


export default request
