import Vue from 'vue'
import VueRouter from 'vue-router'
import store from "@/store"

Vue.use(VueRouter)

const routes = [
  {
    path: '/manage',
    name: 'manage',
    component: () => import(/* webpackChunkName: "about" */ '../views/admin/Manage.vue'),
    redirect: "/manage/profit",
    children:[
      {path: 'profit', name: '收益', component: () => import('../views/admin/Home.vue')},
      {path: 'todaycollect', name: '当日尾款结算订单', component: () => import('../views/admin/Collect.vue')},
      {path: 'salescom', name: '销售提成', component: () => import('../views/admin/SalesCom.vue')},
      {path: 'writercom', name: '写手提成', component: () => import('../views/admin/WriterCom.vue')},
      {path: 'salescomsum', name: '销售提成汇总', component: () => import('../views/admin/SalesComSum.vue')},
      {path: 'writercomsum', name: '写手提成汇总', component: () => import('../views/admin/WriterComSum.vue')},
      {path: 'writerPerformance', name: '写手绩效', component: () => import('../views/admin/WriterPerfomance.vue')},
      {path: 'salePerformance', name: '销售绩效', component: () => import('../views/admin/SalePerfomance.vue')},
      {path: 'salesman', name: '销售管理', component: () => import('../views/admin/Salesman.vue')},
      {path: 'writer', name: '写手管理', component: () => import('../views/admin/Writer.vue')},
      {path: 'admin', name: '管理员管理', component: () => import('../views/admin/Admin.vue')},
      {path: 'order', name: '订单状态', component: () => import('../views/admin/Order.vue')},
      {path: 'orderDetailed', name: '订单详情', component: () => import('../views/admin/OrderDetailed.vue')},
      {path: 'orderDownload', name: '订单下载', component: () => import('../views/admin/orderDownload.vue')},
      {path: 'exportExcel', name: '导出订单信息', component: () => import('../views/admin/exportExcel.vue')},
      {path: 'xlsx1', name: '批量下载论文', component: () => import('../views/admin/ParseXlsx1.vue')},
      {path: 'xlsx2', name: '批量下载开题报告', component: () => import('../views/admin/ParseXlsx2.vue')},
      {path: 'xlsx3', name: '批量下载任务书', component: () => import('../views/admin/ParseXlsx3.vue')},
      {path: 'xlsx4', name: '批量下载查重报告', component: () => import('../views/admin/ParseXlsx4.vue')},
      {path: 'log', name: '日志管理', component: () => import('../views/admin/Log.vue')}

    ]
  },
  {
    path: '/salesman',
    name: 'Salesman',
    component: () => import(/* webpackChunkName: "about" */ '../views/salesman/Salesman.vue'),
    redirect: "/salesman/alarms",
    children:[
      {path: 'alarms', name: '紧急任务', component: () => import('../views/salesman/Alarm.vue')},
      {path: 'piechart', name: '订单统计', component: () => import('../views/salesman/PieChart.vue')},
      {path: 'profit', name: '收益', component: () => import('../views/salesman/Home.vue')},
      {path: 'order', name: '订单', component: () => import('../views/salesman/Order.vue')},

    ]
  },
  {
    path: '/writer',
    name: 'Writer',
    component: () => import(/* webpackChunkName: "about" */ '../views/writer/Writer.vue'),
    redirect: "/writer/alarms",
    children:[
      {path: 'alarms', name: '紧急任务', component: () => import('../views/writer/Alarm.vue')},
      {path: 'profit', name: '收益', component: () => import('../views/writer/Home.vue')},
      {path: 'order', name: '订单', component: () => import('../views/writer/Order.vue')},
      {path: 'list', name: '列表', component: () => import('../views/writer/List.vue')},
      {path: 'upload', name: '上传', component: () => import('../views/writer/Upload.vue')},
    ]
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "about" */ '../views/login/Login.vue')
  },
  {
    path: '/',
    name: 'Login',
    component: () => import(/* webpackChunkName: "about" */ '../views/login/Login.vue')
  }
]

const router = new VueRouter({
  base: process.env.BASE_URL,
  routes
})

//路由守卫
router.beforeEach((to,from,next)=>{
  localStorage.setItem("currentPathName" , to.name) //设置当前路由名称,为了再Header组件中使用
  store.commit("setPath")//触发store的数据更新
  next()//放行路由
})

export default router
